<i18n src="@/common/locales.json"></i18n>

<template>
  <div class="hello">
    <div v-if="webId == null">
      <!-- <b-button class="m-2 mb-5" variant="info text-light" to="/welcome">What is CANDiY?</b-button> -->
    </div>

    <div class="menu-container" style="width: 100%">
      <div>
        <h3>나의 데이터</h3>
        <b-card-group
          deck
          style="display: flex; flex-wrap: wrap;justify-content: space-between;"
        >
          <b-card style="width: 47%">
            <router-link
              to="/storage"
              class="big-button storage"
              style="background-color: #FB5C5C;"
            >
              <p class="btn1-title">{{ $t("mystorage") }}</p>
              <font-awesome-icon icon="fa-solid fa-inbox" size="xl"
            /></router-link>
          </b-card>
          <b-card style="width:47%">
            <router-link
              to="/editor"
              class="big-button connect"
              style="background-color: #5EBDEB;"
              ><p class="btn1-title">{{ $t("connect") }}</p>
              <font-awesome-icon icon="fa-solid fa-pen-to-square" size="xl"
            /></router-link>
          </b-card>

          <!-- <b-card
            header="프로필"
            class="text-center mb-2"
            style="min-width: 20rem;"
          >
            <b-card-text>
              <ul>
                <li class="border border-info rounded">
                  <router-link to="/profile"
                    ><font-awesome-icon
                      icon="fa-solid fa-user"
                      size="1x"
                      class="pr-1"
                    />{{ $t("profile") }}</router-link
                  >
                </li>

                <li class="border border-info rounded">
                  <router-link to="/friends"
                    ><font-awesome-icon
                      icon="fa-solid fa-user-group"
                      size="1x"
                      class="pr-1"
                    />{{ $t("friends") }}</router-link
                  >
                </li>

                <li class="border border-info rounded">
                  <router-link to="/fofri"
                    ><font-awesome-icon
                      icon="fa-solid fa-user-plus"
                      size="1x"
                      class="pr-1"
                    /><span>{{ $t("fofri") }}</span></router-link
                  >
                </li>
              </ul>
            </b-card-text>
          </b-card> -->

          <!-- 
          <b-card bg-variant="dark" text-variant="white" header="Gouvernance" class="text-center mb-2" style="min-width: 20rem;">
            <b-card-text>
              <ul>
                <li><router-link to="/tension/edit"><b-icon-lightning class="border border-info rounded p-2" font-scale="4" variant="info"></b-icon-lightning><br>{{ $t('Tension') }}</router-link></li>
                <li><router-link to="/tensions"><b-icon-card-checklist class="border border-info rounded p-2" font-scale="4" variant="info"></b-icon-card-checklist><br>{{ $t('Tensions') }}</router-link></li>
                <li><router-link to="/groups"><b-icon-building class="border border-info rounded p-2" font-scale="4" variant="info"></b-icon-building><br>{{ $t('Groups') }}</router-link></li>
                <li><router-link to="/parle"><b-icon-chat-text-fill class="border border-info rounded p-2" font-scale="4" variant="info"></b-icon-chat-text-fill><br>{{ $t('talk') }}</router-link></li>
              </ul>
            </b-card-text>
          </b-card> 
          -->

          <!--          <b-card header="Want to Know More?" bg-variant="dark" text-variant="white" class="text-center mb-2" style="min-width: 20rem;">-->
          <!--            <b-card-text>-->
          <!--              <ul>-->
          <!--                <li class="border border-info rounded" style="width: 15rem"><router-link to="/about"><font-awesome-icon icon="fa-solid fa-circle-question" size="1x" class="pr-1"/> CANDiY Technology</router-link></li>-->
          <!--              </ul>-->
          <!--            </b-card-text>-->
          <!--          </b-card>-->
        </b-card-group>
        <h3>도구함</h3>
        <b-card-group
          deck
          style="display: flex; flex-wrap: wrap; justify-content: space-between;"
        >
          <b-card style="width: 31%">
            <router-link to="/bookmarks" class="mini-button downloade"
              ><p class="btn2-title">{{ $t("download") }}</p>
              <font-awesome-icon icon="fa-solid fa-file-arrow-down" size="lg"
            /></router-link>
          </b-card>
          <b-card style="width: 31%">
            <router-link to="/inbox" class="mini-button inbox"
              ><p class="btn2-title">{{ $t("inbox") }}</p>
              <font-awesome-icon
                icon="fa-solid fa-inbox"
                size="lg"/><InboxNotif
            /></router-link>
          </b-card>
          <b-card style="width: 31%">
            <router-link to="/chat" class="mini-button chat"
              ><p class="btn2-title">{{ $t("chat") }}</p>
              <font-awesome-icon icon="fa-solid fa-comments" size="lg"
            /></router-link>
          </b-card>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  components: {
    InboxNotif: () => import("@/components/inbox/InboxNotif"),
  },
  computed: {
    webId() {
      return this.$store.state.solid.webId;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 90%;
  margin: 0 auto 20px;
}
h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #aeaeae;
}
ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
li {
  display: flex;
  margin: 0 5px;
  padding: 0px;
  width: 30%;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
}
a {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}
span {
  display: inline-block;
  width: 60%;
  vertical-align: middle;
  line-height: 1.1;
}
svg {
  color: #fff;
}
ul > li {
  background-color: #fb5c5c;
  width: 35%;
  margin: 0 10px;
}
ul > li.connect {
  background-color: #5ebdeb;
}
.card {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.13);
  border: none;
  margin-bottom: 16px;
}
.card-body {
  padding: 0;
}
.menu-container {
  padding: 0;
}
a.big-button {
  border-radius: 1rem;
  display: block;
  width: 100%;
  height: 15vh;
  text-align: left;
  box-sizing: border-box;
  padding: 20px;
}
a.storage {
  box-shadow: 3px 3px 15px rgba(251, 92, 92, 0.2);
}
a.connect {
  box-shadow: 3px 3px 15px rgba(94, 189, 235, 0.2);
}
a.mini-button {
  border-radius: 1rem;
  display: block;
  width: 100%;
  height: 11vh;
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
  color: #fff;
  background-color: #374467;
}
a.mini-button svg {
  color: #fff;
}
mini-button.inbox {
  position: relative;
}
p.btn1-title {
  font-size: 18px;
  font-weight: 600;
  word-break: keep-all;
  margin-bottom: 5px;
}
p.btn2-title {
  font-size: 16px;
  font-weight: 600;
  word-break: keep-all;
  margin-bottom: 5px;
}
.inbox-notif {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
</style>
